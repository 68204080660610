const initialState = () => ({
  uniqueExperience: [],
});

export default {
  state: initialState(),
  actions: {
    async loadUniqueExperience({ commit }, payload = {}) {
      try {
        let res = await this._vm.$api.get("UniqueExperience", payload);
        if (res.status === 200) {
          console.log("UniqueExperience successfully", res);
          commit("setUniqueExperience", res.data.data);
        }
      } catch (error) {
        console.error("Error loading unique experiences:", error);
      }
    },
    async logExperienceClick(_, payload) {
      try {
        // Send the payload to the backend API
        const res = await this._vm.$api.post("LogExperiencesClick", payload);
        console.log("Experience click logged successfully", res);
      } catch (error) {
        console.error("Error logging experience click:", error);
      }
    },

    async submitExperienceEnquiry(_, payload = {}) {
      try {
        const res = await this._vm.$api.post("UniqueExperience", payload);
        return res.data;
      } catch (error) {
        console.error("Error submitting experience enquiry:", error);
        throw error;
      }
    },
  },
  mutations: {
    setUniqueExperience(state, data) {
      state.uniqueExperience = data;
    },
  },

  getters: {
    getUniqueExperience: (state) => state.uniqueExperience,
  },
};
